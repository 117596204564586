import React, { useContext, useEffect, useRef } from 'react';
import logger from '@sm/logging';
import { Provider as ReduxProvider } from 'react-redux';
import { GetServerSidePropsResult } from 'next';
import Head from 'next/head';
import { defineMessages, t } from '@sm/intl';
import { SurveyThemeProvider } from '@sm/webassets/SurveyTheme/context';
import { formatTheme } from '@sm/webassets/SurveyTheme/helpers';
import { StaticContext } from '@sm/webassets/StaticContext';
import routeHandler from '~helpers/routeHandler';
import surveyTaking from '~helpers/pages/survey-taking';
import setSurveyTakingDocumentTitle from '~app/pages/SurveyTaking/helper/surveyTakingWindowTitle';
import getSpageGoogleFontsLink from '~app/helpers/spageGoogleFonts';
import isValidSubdomain from '~app/helpers/subdomain';
import SurveyPage from '~app/pages/SurveyTaking/v2/Survey';
import { Collector, Survey, ExistingResponse, Respondent, SurveyTheme } from '~app/pages/SurveyTaking/utils/types';

import { store } from '~app/storeV2';

import {
  initializeSurvey,
  setSurveyOwnerPackageId,
  setCollectorKey,
  initEnvironment,
  clearCookie,
  setClientToken,
  initializeCollector,
  initializeRespondent,
  initializeExistingResponses,
} from '~app/pages/SurveyTaking/v2/slices/surveySlice';
import { ErrorType } from '~app/pages/SurveyTaking/errors';
import { setMeta } from '~app/pages/SurveyTaking/v2/slices/errorsSlice';
import { GetRespondentSurveyPageQuery, GetSpageSessionQuery, Maybe } from '~lib/generatedGqlTypes';
import { CustomGetServerSideProps, LayoutData, ServerSideData } from '~helpers/middleware/types';
import { PageOptions, PageWithLayout } from '~helpers/pages/types';
import SEOBanner from '~app/components/SEOBanner';
import { FourOhFourErrorPage } from '~app/components/Errors';
import { StaticContextEnvironment, StaticContextType } from '~app/pages/SurveyTaking/v2/types';
import createCurrentSessionSurvey from '~app/pages/SurveyTaking/helper/createCurrentSessionSurvey';
import { fetchLocaleMessages } from '~helpers/fetchLocaleMessages';
import { ANALYTICS_EVENTS } from '~app/components/amplitudeEnums';
import PageLayout from '~components/pageLayout';
import sendAmplitudeEvent from '~helpers/sendAmplitudeEvent';
import buildPageOptions from '~helpers/buildPageOptions';
import decryptAuthToken from '~helpers/decryptAuthToken';
import { ExpandedSlLanguageLocale } from '~helpers/slLanguageLocale';
import { logAsyncTiming } from '~helpers/measureTiming';

const COPY = defineMessages({
  SEO_BANNER_CLOSE_BUTTON_ARIA_TEXT: {
    id: 'r.page.seoBannerCloseButtonAriaText',
    defaultMessage: 'close SEO banner',
  },
  SEO_BANNER_BODY: {
    id: 'r.page.seoBannerBody',
    defaultMessage: "Is this the survey you're looking for? Try creating your own with the world's leading platform.",
  },
  SEO_BANNER_SIGNUP_BUTTON: {
    id: 'r.page.seoBannerSignupButton',
    defaultMessage: 'Sign up free',
  },
  SEO_BANNER_LEARN_MORE: {
    id: 'r.page.seoBannerLearnMoreButton',
    defaultMessage: 'Learn more',
  },
});

/** Type for `Content` added via middleware to Request['pageProps'] */
type PageContent = {
  spageSessionData: GetSpageSessionQuery;
  surveyPageData: GetRespondentSurveyPageQuery;
  respondent: Respondent | null;
  survey: Survey;
  surveyTheme: SurveyTheme;
  collector: Collector;
  existingResponses: ExistingResponse[];
  collectorKey: string;
  endPageUrl: string;
  isEndPageUrlExternal: boolean;
  pageOptions: PageOptions;
  isNotFound?: boolean;
};

type ServerSideProps = LayoutData &
  PageContent &
  Partial<ServerSideData> & {
    spageGoogleFontsLink: string | null;
    authToken: string;
  };

const log = logger.getLogger('respweb:page:surveyTaking');

const getRedirect = (destination: string, isPermanent: boolean): GetServerSidePropsResult<ServerSideProps> => {
  return {
    redirect: {
      destination,
      permanent: isPermanent,
    },
  };
};

export const getServerSideProps: CustomGetServerSideProps<ServerSideProps> = logAsyncTiming(
  'respweb:surveyTaking:getServerSideProps',
  async ({ req, res, query }) => {
    const collectorKey = query.collectKey as string;
    const authToken = req.headers['x-sm-auth-id'] as string | undefined;
    const refreshToken = req.headers['x-sm-auth-refresh'] as string | undefined;
    const pathToOldSurveyTaking = `/r/${collectorKey}?rexr_p=current`;

    /**
     * The auth token and refresh token are required and AuthProxy always sets these values for the requests that
     * make it to RespWeb. This is just as a safety check to make sure that the tokens are present.
     */
    if (!authToken || !refreshToken) {
      log.error('Missing auth.', { collectorKey });
      return getRedirect(pathToOldSurveyTaking, false);
    }

    const authTokenParams = decryptAuthToken(authToken);
    const rpCookieValue = req.cookies[`RP_${authTokenParams.collectorId}`];
    const handler = routeHandler({ query, owners: '@spage' });
    await handler.run(req, res);
    const pageOptions = buildPageOptions(req);

    const { updatedPageOptions, ...pageProps } = await surveyTaking.fetchServerSideData({
      ...authTokenParams,
      reqLocale: pageOptions.reqLocale,
      authToken,
      rpCookieValue,
      collectorKey,
      pageRequest: req, // pass in page request for apollo client initialization in fetchServerSideData()
    });
    const newPageOptions = {
      ...pageOptions,
      ...updatedPageOptions,
    };

    /**
     * Override the language based on the request locale if it's supported or default from the survey settings.
     * See `fetchServerSideData` how it sets the reqLocale on the pageOptions.
     */
    req.overrideLanguage(newPageOptions?.reqLocale ?? 'en');

    if (
      !pageProps?.fetchServerSideDataSuccess ||
      !pageProps?.spageSessionData ||
      !pageProps?.surveyPageData ||
      !pageProps?.collector ||
      !pageProps?.survey ||
      !pageProps?.surveyTheme
    ) {
      // only redirect to ResponseWeb in production mode to avoid confusion during development
      if (process.env.NODE_ENV === 'development') {
        throw Error('Error Fetching ServerSideData via apps/respweb/helpers/pages/survey-taking/index.ts');
      }
      if (pageProps.isNotFound) {
        log.error('Collector Not Found', { collectorKey: newPageOptions?.collectorKey });
        // TODO: follow up in WEBPLAT-4505
        return getRedirect('/404', false);
      }
      return getRedirect(pathToOldSurveyTaking, false);
    }

    const { shouldShowSurveyTaken, collectorStatus, encryptedSmParam, collectorAllowMultipleResponses } =
      newPageOptions ?? {};

    const languageCode = newPageOptions?.languageCode;
    const slLanguageLocale = newPageOptions?.slLanguageLocale;

    if (collectorStatus === 'CLOSED') {
      return getRedirect(`/survey-closed/?sm=${encryptedSmParam || ''}&lang=${languageCode}`, false);
    }

    if (!collectorAllowMultipleResponses && shouldShowSurveyTaken) {
      return getRedirect(`/survey-taken/?sm=${encryptedSmParam || ''}&lang=${languageCode}`, false);
    }

    const translationData = await fetchLocaleMessages(slLanguageLocale as ExpandedSlLanguageLocale, languageCode);

    const spageGoogleFontsLink = getSpageGoogleFontsLink(pageProps?.surveyTheme);

    return {
      props: {
        ...(req.payloads as PageContent),
        /**
         * The actual auth token expries every 5 minutes and that brings up some challanges. Auth proxy team
         * suggested to use the refresh token from the client side because it  does not expire. This refresh token
         * is replaced by auth proxy with a new auth token. This not ideal and refresh token should only be used by
         * authorization server as per specification (of OAuth2).
         *
         * Future: Respweb should be able to refresh the toke by itself if it's expired.
         */
        authToken: refreshToken,
        translationData,
        ...pageProps,
        pageOptions: newPageOptions,
        spageGoogleFontsLink,
        layout: {
          variant: 'SurveyMonkey',
          options: {
            actionFlow: 'SurveyTaking',
            pageId: 'SurveyTaking',
            legacyWeb: 'responseweb',
            includeHeader: false,
            includeFooter: false,
          },
        },
        survey: pageProps.survey,
        collector: pageProps.collector,
        existingResponses: pageProps.existingResponses,
        spageSessionData: pageProps.spageSessionData,
        surveyPageData: pageProps.surveyPageData,
        respondent: pageProps.respondentData,
        surveyTheme: pageProps.surveyTheme,
        collectorKey,
      },
    };
  }
);

const initializeSurveyTakingStore = ({
  environment,
  collector,
  collectorKey,
  spageSessionData,
  surveyPageData,
  hasSurveyVersionChanged,
  pageOptions,
  endPageUrl,
  respondent,
  existingResponses,
  authToken,
  shouldRemoveRPCookie,
  surveyOwnerPackageId,
  encryptedSmParam,
  survey,
  isEndPageUrlExternal,
  isWhiteLabel,
}: {
  environment: StaticContextEnvironment;
  collector: Collector;
  collectorKey: string;
  spageSessionData: GetSpageSessionQuery;
  surveyPageData: GetRespondentSurveyPageQuery;
  hasSurveyVersionChanged: boolean;
  pageOptions: PageOptions;
  endPageUrl: string;
  respondent: Maybe<Respondent>;
  existingResponses: ExistingResponse[];
  authToken: string;
  shouldRemoveRPCookie?: boolean;
  surveyOwnerPackageId: string;
  encryptedSmParam?: Maybe<string>;
  survey: Survey;
  isEndPageUrlExternal: boolean;
  isWhiteLabel: boolean;
}): void => {
  store.dispatch(initEnvironment(environment));
  store.dispatch(initializeCollector(collector));
  store.dispatch(
    initializeSurvey({
      respondentSession: spageSessionData.spageSession,
      surveyTakingCurrentPage: surveyPageData,
      surveyChangedOnPageReload: hasSurveyVersionChanged,
      survey,
      endPageUrl,
      encrytedSurveyParams: pageOptions.encryptedSmParam ?? undefined,
      isEndPageUrlExternal,
      isWhiteLabel,
    })
  );
  store.dispatch(initializeRespondent(respondent));
  store.dispatch(initializeExistingResponses(existingResponses));
  store.dispatch(setClientToken(authToken));

  if (shouldRemoveRPCookie) {
    // clear cookies to treat survey taker as a brand new respondent
    store.dispatch(clearCookie(`RP_${collector.id}`));
  }

  store.dispatch(setSurveyOwnerPackageId(surveyOwnerPackageId));
  store.dispatch(setCollectorKey(collectorKey));

  store.dispatch(
    setMeta({
      [ErrorType.COLLECTOR_CLOSED]: { url: `/survey-closed/?sm=${encryptedSmParam}` },
      [ErrorType.REQUEST_IP_DISALLOWED]: { url: `/survey-closed/?sm=${encryptedSmParam}` },
    })
  );
};

const SurveyTaking: PageWithLayout<ServerSideProps> = ({
  pageOptions,
  spageSessionData,
  surveyPageData,
  surveyTheme: surveyThemeData,
  collectorKey,
  survey: surveyData,
  collector,
  endPageUrl,
  isEndPageUrlExternal,
  existingResponses,
  respondent,
  hasSurveyVersionChanged = false,
  spageGoogleFontsLink,
  authToken,
}) => {
  const { shouldRemoveRPCookie, reqLocale, slLanguageLocale, isWhiteLabel = false, canonical = '' } = pageOptions;
  const { surveyOwnerPackageId } = spageSessionData.spageSession;
  const { encryptedSmParam } = pageOptions;
  const { environment, pageRequestId } = useContext<StaticContextType>(StaticContext);
  const collectorSubdomain = collector.weblink?.subdomain;
  const subDomain = pageOptions?.subDomain ?? 'www';

  const survey = createCurrentSessionSurvey(surveyData, surveyThemeData);

  const isMounted = useRef(false);
  useEffect(() => {
    if (!isMounted.current) {
      sendAmplitudeEvent(ANALYTICS_EVENTS.SURVEY_LOADED, {
        collector,
        survey,
        surveyOwnerPackageId,
        questions: surveyPageData.surveyPage?.surveyPageQuestions,
        isMultilingual: !!survey.availableSurveyLanguages?.length,
      });
      isMounted.current = true;
    }
  }, [collector, survey, surveyOwnerPackageId, surveyPageData.surveyPage?.surveyPageQuestions]);

  if (!survey || !isValidSubdomain([subDomain], collectorSubdomain)) {
    return <FourOhFourErrorPage requestId={pageRequestId} />;
  }

  // seo banner config
  const seoBannerCallToActionButtons = [
    {
      label: t(COPY.SEO_BANNER_SIGNUP_BUTTON),
      uri: 'https://www.surveymonkey.com/user/sign-up/?ut_source=s_page_search_topbar_desktop',
    },
    {
      label: t(COPY.SEO_BANNER_LEARN_MORE),
      uri: 'https://www.surveymonkey.com/mp/take-a-tour/?ut_source=s_page_search_topbar_desktop',
    },
  ];

  const respondentCollectionMethod = respondent?.collectionMethod ?? null;
  const isTitleEnabled = survey?.design?.surveyTitle.enabled ?? true;
  const surveyTitle = survey?.titleHTML || survey?.title;

  if (!collector?.surveyId) {
    return null;
  }

  initializeSurveyTakingStore({
    environment,
    collector,
    collectorKey,
    spageSessionData,
    surveyPageData,
    hasSurveyVersionChanged,
    pageOptions,
    endPageUrl,
    respondent,
    existingResponses,
    authToken,
    shouldRemoveRPCookie,
    surveyOwnerPackageId,
    encryptedSmParam,
    survey,
    isEndPageUrlExternal,
    isWhiteLabel,
  });

  // TODO: fix this type later
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const surveyTheme = formatTheme({ survey: { ...survey } } as any);

  // SPAGE-7677: REX router debug info
  const domain = environment.domain.toLowerCase();
  const showRequestId = ['surveymonkey', 'research'].every(d => d !== domain);

  const weblinkConfirmationToggle = !!collector.weblink && (collector.confirmationEmailEnabled ?? false);

  /* @todo: Lang set on the document level https://jira.surveymonkey.com/browse/WEBPLAT-3418 */
  return (
    <ReduxProvider store={store}>
      <Head>
        <title>{setSurveyTakingDocumentTitle(isTitleEnabled, isWhiteLabel, surveyTitle)}</title>
        <meta name="twitter:card" content="summary_large_image" />
        {collector?.customMetaData?.title && <meta property="og:title" content={collector?.customMetaData?.title} />}
        {collector?.customMetaData?.imageUrl && (
          <meta property="og:image" content={collector?.customMetaData?.imageUrl} />
        )}
        {collector?.customMetaData?.description && (
          <meta property="og:description" content={collector?.customMetaData?.description} />
        )}
        <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=yes" />
        <meta name="robots" content="noindex, nofollow" />
        <meta httpEquiv="content-language" content={slLanguageLocale} />
        <link rel="canonical" href={canonical} />
        {!!spageGoogleFontsLink && <link rel="stylesheet preload" href={spageGoogleFontsLink} as="style" />}
        {/* TODO: Part of WEBPLAT-3482
         * The earlier HTML <link> tag incorrectly set `async` attribute which exists only in `<script>` tags.
         * The above line can achieve similar effect whereas might be better go with the below line as well.
         *
         * <link rel="stylesheet" href={spageGoogleFontsLink} media="print" onload={this.media='all'} /> */}
      </Head>
      <SurveyThemeProvider theme={surveyTheme}>
        <SEOBanner
          callToActionButtons={seoBannerCallToActionButtons}
          closeButtonAriaText={t(COPY.SEO_BANNER_CLOSE_BUTTON_ARIA_TEXT)}
          requestFromSearchEngine={false}
          collectionMethod={respondentCollectionMethod}
          collectorType={collector?.type}
        >
          {t(COPY.SEO_BANNER_BODY)}
        </SEOBanner>
        <SurveyPage
          hasSurveyVersionChanged={hasSurveyVersionChanged}
          pageRequestId={pageRequestId}
          showRequestId={showRequestId}
          reqLocale={reqLocale ?? undefined}
          showWeblinkConfirmationToggle={weblinkConfirmationToggle}
        />
      </SurveyThemeProvider>
    </ReduxProvider>
  );
};

SurveyTaking.getLayout = function getLayout(page) {
  const { layout, staticData, translationData } = page.props;

  return (
    <PageLayout layout={layout} staticData={staticData} translationData={translationData}>
      {page}
    </PageLayout>
  );
};

export default SurveyTaking;
