/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExternalQuestion, RespondentAnswerTypes } from '@sm/question-widgets/respondent-survey';
import Cookies from 'js-cookie';
import { AppDispatch, RootState } from '~app/storeV2';

import {
  CurrentSessionSurvey,
  PageQuestions,
  QuestionAnswers,
  StaticContextEnvironment,
  SubmitMutation,
  SubmitMutationForMultipage,
  SurveyTakingCurrentPage,
} from '../types';

import { SurveyFormat } from '~app/components/Survey/SurveyFormat/constants';
import answersMatch from '~app/helpers/answersMatch';
import { Collector, ExistingResponse, Respondent, Survey } from '~app/pages/SurveyTaking/utils/types';
import { GetSpageSessionQuery, RespApiAnswerInput } from '~lib/generatedGqlTypes';
import { DEFAULT_SUBMIT_RESULT, REDIRECT_TYPE } from '../../constants';
import { transformToSurveyErrors } from '../../errors/transformToSurveyErrors';
import { mapSurveyAnswersToResponse } from '../../helper/mapSurveyAnswersToResponse';
import { deferToAfterFocusChanged, validate } from '../../validation';
import { removeErrorsById, setErrorsById } from './errorsSlice';

// ========== INITIAL STATE
const emptyPageIds: readonly number[] = Object.freeze([]);
export type SurveyTakingPayload = {
  respondentSession: GetSpageSessionQuery['spageSession'];
  survey: Survey;
  surveyTakingCurrentPage: SurveyTakingCurrentPage;
  surveyChangedOnPageReload: boolean;
  endPageUrl: string;
  isEndPageUrlExternal: boolean;
  encrytedSurveyParams?: string;
  isWhiteLabel: boolean;
};

export type SurveyMode = (typeof SurveyView)[keyof typeof SurveyView];

export type SurveyState = {
  surveyTakingPayload?: SurveyTakingPayload;
  /** The survey object */
  survey?: CurrentSessionSurvey;
  /** The active page object */
  activePage?: SurveyTakingCurrentPage;
  /** The collector object */
  collector?: Collector;
  /**
   * The list of existing responses for this survey taker. List is empty if there are no existing responses.
   * This list of responses is used to also determine if changes are made to the existing answers. So this list
   * remains the same throughout the survey taking process (unless the browser's page is refreshed).
   *
   * Must be readonly to prevent accidental mutation.
   */
  readonly existingResponsesOnServer: ExistingResponse[];
  /** The respondent object */
  respondent?: Respondent;
  /** The client token */
  clientToken?: string;
  /** The answers for the current page */
  answers?: QuestionAnswers;
  questionsWithAnswers?: QuestionAnswers;
  questions?: PageQuestions;
  deleteQuestionIds: string[];
  /** To track what view is shown to user */
  surveyView: SurveyMode;
  /* Redirect URL based off collector's Survey End Page feature */
  surveyEndPageUrl: string | null;
  surveyEndPageUrlIsExternal: boolean;
  /* Encrypted SMParams when instant results is enabled */
  encryptedInstantResultsSmParam: string | null;
  currentPageId?: string;
  encrytedSurveyParams?: string;
  isWhiteLabel: boolean;
  pageIds?: number[];
  // TODO: Collector Key null is it a valid case?
  collectorKey: string | null;
  /* StaticContext Environment */
  environment?: StaticContextEnvironment;
  surveyOwnerPackageId?: string;
  surveyChangedOnPageReload: boolean;
};

/** Different views that are designed like a survey page but have different content */
export const SurveyView = {
  Taking: 'TAKING',
  ThankYou: 'THANK_YOU',
  Password: 'PASSWORD',
  CloseWindow: 'CLOSE_WINDOW',
} as const;

export const initialState: SurveyState = {
  surveyTakingPayload: undefined,
  survey: undefined,
  activePage: undefined,
  collector: undefined,
  clientToken: undefined,
  questions: { items: [] },
  answers: {},
  questionsWithAnswers: undefined,
  deleteQuestionIds: [],
  surveyView: SurveyView.Taking,
  surveyEndPageUrl: 'abc',
  surveyEndPageUrlIsExternal: false,
  encryptedInstantResultsSmParam: 'abc',
  currentPageId: undefined,
  pageIds: undefined,
  collectorKey: null,
  encrytedSurveyParams: undefined,
  environment: undefined,
  surveyChangedOnPageReload: false,
  existingResponsesOnServer: [],
  isWhiteLabel: false,
};

type SurveyCompleteAction = {
  reqLocale: string;
};

type SetCookieArgs = {
  name: string;
  value: string;
  expiration?: number | Date;
};

type SurveyPagePayload = {
  activePage?: SurveyTakingCurrentPage;
  questions?: PageQuestions;
  answers?: QuestionAnswers;
  clientToken: string;
};

type PostSurveyCompleteCleanupArgs = {
  collectorKey: string | null;
};

/**
 * Maps server responses to application state answers.
 *
 * @param {PageQuestions} questions - The list of questions on the current page.
 * @param {ExistingResponse[]} existingResponsesOnServer - The list of responses from the server.
 * @returns {QuestionAnswers} - The mapped answers in the application state format.
 */
const mapServerResponsesToAnswers = (
  questions: PageQuestions,
  existingResponsesOnServer: ExistingResponse[]
): QuestionAnswers => {
  const result: QuestionAnswers = {};

  // Iterate over each question on the current page
  questions?.items.flatMap(question => {
    // Filter responses to only include those that match the current question's ID
    const existingResponse = existingResponsesOnServer
      .filter(backendResponse => backendResponse.questionId === question.id)
      .map(backendResponse => {
        // Check if the response is for a MATRIX question with SINGLE_ANSWER_RADIO variant
        if (
          backendResponse.answerId &&
          question.family === 'MATRIX' &&
          'variant' in question &&
          question.variant === 'SINGLE_ANSWER_RADIO'
        ) {
          // Return the response in the format expected for MATRIX SINGLE_ANSWER_RADIO questions
          // This is a special case where the answer ID is the same as the answer number.
          return {
            id: backendResponse.answerId,
            value: [{ id: backendResponse.answerNum, value: backendResponse.answerNum }],
          };
        }
        // Check if the response contains answer text, this is for a typed base type of answer
        if (backendResponse.answerText) {
          // Return the response with the answer text
          return { id: backendResponse.answerId, value: backendResponse.answerText };
        }
        // Check if the response contains a non-zero answer number
        if (backendResponse.answerNum && backendResponse.answerNum !== '0') {
          // Return the response with the answer number
          return { id: backendResponse.answerId, value: backendResponse.answerNum };
        }
        // Default case: return the response with the answer ID
        return { id: backendResponse.answerId, value: backendResponse.answerId };
      });

    result[question.id] = {
      questionId: question.id,
      values: existingResponse as QuestionAnswers[0]['values'],
      isDirty: false,
      touched: false,
    };
    return existingResponse;
  });

  return result;
};

// ========== SLICE

const sliceName = 'survey';

export const surveySlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    initEnvironment: (state, action: PayloadAction<StaticContextEnvironment>) => {
      state.environment = action.payload;
    },
    initializeCollector: (state, action: PayloadAction<Collector>) => {
      state.collector = action.payload;
    },
    initializeExistingResponses: (state, action: PayloadAction<ExistingResponse[]>) => {
      state.existingResponsesOnServer = action.payload;
      if (state.questions) {
        state.answers = mapServerResponsesToAnswers(state.questions, state.existingResponsesOnServer);
      }
    },
    initializeRespondent: (state, action: PayloadAction<Respondent | null>) => {
      if (action.payload !== null) {
        state.respondent = action.payload;
        state.encryptedInstantResultsSmParam = action.payload.encryptedInstantResultsSmParam;
      }
    },
    initializeSurvey: (state, action: PayloadAction<SurveyTakingPayload>) => {
      state.surveyTakingPayload = action.payload;
      state.survey = action.payload.survey;
      state.activePage = action.payload.surveyTakingCurrentPage ?? {};
      state.pageIds = action.payload.respondentSession.pageIds;
      state.currentPageId = action.payload.surveyTakingCurrentPage?.surveyPage?.id;
      state.questions = action.payload.surveyTakingCurrentPage?.surveyPage?.surveyPageQuestions;
      state.encrytedSurveyParams = action.payload.encrytedSurveyParams;
      state.surveyChangedOnPageReload = action.payload.surveyChangedOnPageReload;
      state.surveyEndPageUrl = action.payload.endPageUrl;
      state.surveyEndPageUrlIsExternal = action.payload.isEndPageUrlExternal;
      state.isWhiteLabel = action.payload.isWhiteLabel;

      // initialize answers
      state.answers =
        action.payload.surveyTakingCurrentPage?.surveyPage?.surveyPageQuestions?.items?.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: {
              questionId: curr.id,
              values: [],
              touched: false,
              isDirty: false,
            },
          }),
          {}
        ) ?? {};
    },
    setSurveyChangedOnPageReload: (state, action: PayloadAction<boolean>) => {
      state.surveyChangedOnPageReload = action.payload;
    },
    setAnswers: (state, action: PayloadAction<QuestionAnswers | undefined | {}>) => {
      state.answers = action.payload;
    },
    setQuestions: (state, action: PayloadAction<PageQuestions>) => {
      state.questions = action.payload;
    },
    setActivePage: (state, action: PayloadAction<SurveyPagePayload>) => {
      const { activePage, questions = { items: [] } } = action.payload;
      state.activePage = activePage;
      state.questions = questions;
      state.currentPageId = activePage?.surveyPage?.id;
      state.clientToken = action.payload.clientToken;
      const { answers } = state;
      const answersConvertedFromBackend = mapServerResponsesToAnswers(questions, state.existingResponsesOnServer);
      if (answers) {
        const mergedAnswers: QuestionAnswers = {};
        questions?.items.forEach(question => {
          const currentValues = answers[question.id]?.values ?? [];
          if (!currentValues.length) {
            mergedAnswers[question.id] = answersConvertedFromBackend[question.id];
          } else {
            mergedAnswers[question.id] = answers[question.id];
          }
        });
        state.answers = { ...answers, ...mergedAnswers };
      } else {
        state.answers = answersConvertedFromBackend;
      }
    },
    updateAnswers: (state, action: PayloadAction<QuestionAnswers>) => {
      const mergedAnswers = {
        ...state.answers,
        ...action.payload,
      };
      state.answers = mergedAnswers;
    },
    updateQuestionsWithNewAnswers: (state, action: PayloadAction<QuestionAnswers>) => {
      state.questionsWithAnswers = action.payload;
    },
    updateDeleteQuestionIds: (state, action: PayloadAction<string>) => {
      state.deleteQuestionIds = [...state.deleteQuestionIds, action.payload];
    },
    resetDeleteQuestionIds: state => {
      state.deleteQuestionIds = [];
    },
    resetDirtyAnswers: (state, action: PayloadAction<RespApiAnswerInput[]>) => {
      if (action.payload && action.payload.length > 0) {
        const { answers } = state;

        action.payload.forEach(answer => {
          const { questionId } = answer;
          if (answers?.[questionId]) {
            answers[questionId].isDirty = false;
            answers[questionId].touched = false;
          }
        });
        state.answers = { ...answers };
      }
    },
    setClientToken: (state, action: PayloadAction<string>) => {
      state.clientToken = action.payload;
    },
    setCurrentPageId: (state, action: PayloadAction<string>) => {
      state.currentPageId = action.payload;
    },
    setEncrytedSurveyParams: (state, action: PayloadAction<string>) => {
      state.encrytedSurveyParams = action.payload;
    },
    setPageIds: (state, action: PayloadAction<number[]>) => {
      state.pageIds = action.payload;
    },
    setCollectorKey: (state, action: PayloadAction<string>) => {
      state.collectorKey = action.payload;
    },
    setSurveyView: (state, action: PayloadAction<SurveyMode>) => {
      state.surveyView = action.payload;
    },
    setSurveyOwnerPackageId: (state, action: PayloadAction<string>) => {
      state.surveyOwnerPackageId = action.payload;
    },
    clearCookie: (state, action: PayloadAction<string>) => {
      const { environment: { domain, tld } = {} } = state;
      Cookies.remove(action.payload, { path: '/', domain: `.${domain}.${tld}` });
    },
    setCookie: (state, action: PayloadAction<SetCookieArgs>) => {
      const { environment: { domain, tld } = {} } = state;
      const { name, value, expiration } = action.payload;

      Cookies.set(name, value, {
        expires: expiration,
        domain: `.${domain}.${tld}`,
        secure: true,
        sameSite: 'None',
      });
    },
    surveyComplete: (state, action: PayloadAction<SurveyCompleteAction>) => {
      const redirectType = state.collector?.weblink?.redirectType;
      const isCloseWindowRedirectType = redirectType === REDIRECT_TYPE.closeWindow;
      const isLoopRedirectType = redirectType === REDIRECT_TYPE.loop;
      const isThankYouEnabled = state.collector?.thankYouPage?.isEnabled ?? false;
      const surveyResultsUrl = state.collector?.surveyResultsUrl ?? null;

      const { reqLocale } = action.payload;

      const { environment } = state;
      const isCurrentViewCustomThankYou = state.surveyView === SurveyView.ThankYou;

      const showQuizResultsPage = state.survey?.isQuiz && state.survey.quizOptions?.showResults;
      const showInstantResultsPage = !showQuizResultsPage && surveyResultsUrl;
      const showThankYouView = !showInstantResultsPage && isThankYouEnabled && !isCurrentViewCustomThankYou;
      const surveyEndPageRedirect = !showThankYouView;

      let redirectUrl: string | null = null;
      const redirectUrlParams = `?sm=${state.encrytedSurveyParams}&lang=${reqLocale}`;

      if (showQuizResultsPage) {
        if (surveyResultsUrl) {
          Cookies.set('sm_ir', state.encryptedInstantResultsSmParam ?? '', {
            domain: `.${environment?.domain}.${environment?.tld}`,
            secure: true,
            sameSite: 'None',
          });
        }

        redirectUrl = `/r/quiz/results${redirectUrlParams}`;
      } else if (showInstantResultsPage) {
        Cookies.set('sm_ir', state.encryptedInstantResultsSmParam ?? '', {
          domain: `.${environment?.domain}.${environment?.tld}`,
          secure: true,
          sameSite: 'None',
        });

        redirectUrl = `${surveyResultsUrl}${redirectUrlParams}`;
      } else if (showThankYouView) {
        state.surveyView = SurveyView.ThankYou;
      } else if (isCloseWindowRedirectType) {
        state.surveyView = SurveyView.CloseWindow;
      } else if (surveyEndPageRedirect) {
        const skipUrlParams = isLoopRedirectType || state.surveyEndPageUrlIsExternal;
        redirectUrl = `${state.surveyEndPageUrl}${skipUrlParams ? '' : redirectUrlParams}`;
      }

      if (redirectUrl) {
        window.location.replace(redirectUrl);
      }
    },
    reset: () => initialState,
    postSurveyCompleteCleanup: (state, action: PayloadAction<PostSurveyCompleteCleanupArgs>) => {
      const { collectorKey } = action.payload;
      Cookies.remove(`RE_${collectorKey}`, { path: '/' });
      Cookies.remove(`REPID_${collectorKey}`, { path: '/' });
    },
  },
});

// ========== ACTIONS

export const {
  initializeSurvey,
  initializeCollector,
  initializeExistingResponses,
  initializeRespondent,
  initEnvironment,
  setAnswers,
  setQuestions,
  setActivePage,
  updateAnswers,
  updateQuestionsWithNewAnswers,
  updateDeleteQuestionIds,
  resetDeleteQuestionIds,
  setClientToken,
  reset,
  setSurveyView,
  surveyComplete,
  setCurrentPageId,
  setEncrytedSurveyParams,
  setPageIds,
  setCollectorKey,
  setCookie,
  clearCookie,
  resetDirtyAnswers,
  setSurveyOwnerPackageId,
  postSurveyCompleteCleanup,
  setSurveyChangedOnPageReload,
} = surveySlice.actions;

// ========== THUNKS

/**
 * Update an answer - the specific answer is inferred by the `questionId` inside the answer object.
 * Error validation is also run for the new answer and the errors state is updated accordingly.
 *
 */
export const updateAnswer = createAsyncThunk(`${sliceName}/updateAnswer`, (answer: RespondentAnswerTypes, thunkAPI) => {
  const { questionId } = answer;
  const { surveyState } = thunkAPI.getState() as RootState;
  const { answers, deleteQuestionIds } = surveyState;
  const dispatch = thunkAPI.dispatch as AppDispatch;

  if (!answer.touched) {
    return; // no need for updates if answer was not touched
  }

  const { isValid, errors } = validate(questionId);
  deferToAfterFocusChanged(() => {
    // defer to make done with error-change click work
    if (!isValid) {
      const questionErrors = transformToSurveyErrors(errors);
      dispatch(setErrorsById({ id: questionId, newErrors: questionErrors }));
    } else {
      dispatch(removeErrorsById(questionId));
    }
  });

  const mergedAnswers = { ...surveyState.answers, ...{ [questionId]: answer } };

  // updating for OQAAT
  dispatch(updateQuestionsWithNewAnswers(mergedAnswers));

  const currentAnswer = answers?.[questionId];
  const hasExistingValues = !!currentAnswer?.values?.length;
  const isAlreadyDeletedQuestion = deleteQuestionIds.includes(questionId);
  const doAnswersMatch = answersMatch(answer.values, currentAnswer?.values ?? []);

  if (!isAlreadyDeletedQuestion && hasExistingValues && !doAnswersMatch) {
    dispatch(updateDeleteQuestionIds(questionId));
  }

  dispatch(
    updateAnswers({
      [questionId]: { ...(surveyState.answers?.[questionId] ?? {}), ...answer },
    })
  );
});

/**
 * Submit the survey response.
 * Currently requires the mutation function to be passed as a parameter as it is generated by hook.
 * Note: dateStarted and dateCompleted also need to be passed in as part of a solution to
 * fix failing integration tests involving mocked timers and promises.
 *  */
// to be updated with responsesAPI integration
export const submitSurvey = createAsyncThunk(
  `${sliceName}/submit`,
  async (
    {
      mutation,
      dateStarted,
      dateCompleted,
      reqLocale,
      isValid,
      confirmationEmail,
    }: {
      mutation: SubmitMutation;
      dateStarted: string;
      dateCompleted: string;
      reqLocale: string;
      isValid: boolean;
      confirmationEmail: string | null;
    },
    { getState }
  ) => {
    const { surveyState } = getState() as RootState;
    if (surveyState.survey && surveyState.collector) {
      try {
        // Only use the answered questions in payload (SPAGE-7410)
        const responses = mapSurveyAnswersToResponse({
          questions: (surveyState.activePage?.surveyPage?.surveyPageQuestions?.items ?? []) as ExternalQuestion[],
          answers: surveyState.answers ?? {},
          pageId: surveyState.activePage?.surveyPage?.id ?? '',
          isPrevious: false,
          isValid,
        }) as Required<RespApiAnswerInput[]>; // this typecast is necessary because gql InputMaybe types expect null values, which are not allowed in the response

        const { id: collectorId, isAnonymous, thankYouPage, surveyResultsUrl } = surveyState.collector;
        const {
          version,
          language: { id: surveyLangId },
          isQuiz,
          quizOptions,
        } = surveyState.survey;
        const generateQuizResultsSMParams = isQuiz && (quizOptions?.showResults ?? false);

        /**
         * Mutation calls SurveyResponses.graphql::submitSurveyResponse, which calls
         * @see https://github.com/mntv-webplatform/smweb/blob/master/api/graphapi/src/repos/responses/index.ts#L108
         * in turn
         * @see https://code.corp.surveymonkey.com/devmonkeys/ResponseWeb/blob/develop/responseweb/views/response.py#L167
         */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const { data: { submitSurveyResponse } = {} } = await mutation({
          variables: {
            surveyId: surveyState.survey.id ?? '',
            collectionKey: surveyState.collectorKey ?? '',
            input: {
              collectorId,
              isAnonymous: isAnonymous ?? false,
              surveyVersion: version ?? 1,
              dateStarted,
              dateCompleted,
              respondentId: '',
              responses,
              generateQuizResultsSMParams,
              surveyLangId,
              redirectUrl: surveyState.surveyEndPageUrl,
              reqLocale,
              isThankYouEnabled: thankYouPage?.isEnabled ?? false,
              instantResultsUrl: surveyResultsUrl ?? null,
              multiPageAnswerInput: null,
              confirmationEmail,
              deleteQuestionIds: surveyState.deleteQuestionIds,
              collectorKey: surveyState.collectorKey,
              isPreviousPage: false,
              isSurveyComplete: true,
            },
          },
        });

        return submitSurveyResponse;
      } catch (error: unknown) {
        // SPAGE-9381 - To be completed in server error validation story
        console.log(error);
      }
    }
    return DEFAULT_SUBMIT_RESULT;
  }
);

/*
 * Submit survey response to responsesAPI v3.
 *
 * Currently requires the mutation function to be passed as a parameter as it is generated by hook.
 * Note: dateStarted and dateCompleted also need to be passed in as part of a solution to
 * fix failing integration tests involving mocked timers and promises.
 */
export const submitResponsesV3 = createAsyncThunk(
  `${sliceName}/submitResponsesV3`,
  async (
    {
      mutation,
      dateStarted,
      dateCompleted,
      reqLocale,
      currentPageId,
      nextPageId,
      respondentId,
      isFinalSubmit,
      collectorKey,
      isValid,
      isPrevious,
      confirmationEmail,
    }: {
      mutation: SubmitMutationForMultipage;
      dateStarted: string;
      dateCompleted: string;
      reqLocale: string;
      currentPageId: string;
      nextPageId: string;
      respondentId: string;
      isFinalSubmit: boolean;
      collectorKey: string;
      isValid: boolean;
      isPrevious: boolean;
      confirmationEmail: string | null;
    },
    { getState, dispatch }
  ) => {
    const { surveyState } = getState() as RootState;
    if (surveyState.survey && surveyState.collector) {
      try {
        // Only use the answered questions in payload (SPAGE-7410)
        const pageId = surveyState.activePage?.surveyPage?.id ?? '';
        const responses = mapSurveyAnswersToResponse({
          questions: (surveyState.activePage?.surveyPage?.surveyPageQuestions?.items ?? []) as ExternalQuestion[],
          answers: surveyState.answers ?? {},
          pageId,
          isValid,
          isPrevious,
        }) as Required<RespApiAnswerInput[]>; // this typecast is necessary because gql InputMaybe types expect null values, which are not allowed in the response

        const { id: collectorId, isAnonymous, thankYouPage, surveyResultsUrl } = surveyState.collector;
        const {
          version,
          language: { id: surveyLangId, code: languageCode },
          isQuiz,
          quizOptions,
        } = surveyState.survey;
        const generateQuizResultsSMParams = isQuiz && (quizOptions?.showResults ?? false);

        const data = await mutation({
          variables: {
            surveyId: surveyState.survey.id,
            collectionKey: collectorKey ?? '',
            languageCode,
            pageId: currentPageId === nextPageId ? currentPageId : nextPageId,
            input: {
              respondentId,
              collectorId,
              isAnonymous: isAnonymous ?? false,
              surveyVersion: version,
              dateStarted,
              dateCompleted,
              responses,
              generateQuizResultsSMParams,
              surveyLangId,
              redirectUrl: surveyState.surveyEndPageUrl,
              reqLocale,
              isThankYouEnabled: thankYouPage?.isEnabled ?? false,
              instantResultsUrl: surveyResultsUrl ?? null,
              multiPageAnswerInput: {
                currentPageId,
                nextPageId,
                isFinalSubmit,
                collectorKey,
                isPreviousPage: isPrevious,
              },
              confirmationEmail,
              deleteQuestionIds: surveyState.deleteQuestionIds,
              collectorKey: surveyState.collectorKey,
              isSurveyComplete: isFinalSubmit,
              isPreviousPage: isPrevious,
            },
          },
        });
        dispatch(resetDeleteQuestionIds());
        dispatch(resetDirtyAnswers(responses));
        return data.data?.submitSurveyResponse;
      } catch (error: unknown) {
        // To be completed in server error validation story
        console.log(error);
      }
    }

    return {
      ...DEFAULT_SUBMIT_RESULT,
      surveyPage: null,
    };
  }
);

// ========== SELECTORS

/** The survey state object */
export const selectSurveyState = (state: RootState): SurveyState => state.surveyState;

/** The survey object */
export const selectSurvey = createSelector(selectSurveyState, surveyState => surveyState.survey);

/** The current page object */
export const selectActivePage = createSelector(selectSurveyState, surveyState => surveyState.activePage);

/** The collector object */
export const selectCollector = createSelector(selectSurveyState, surveyState => surveyState.collector);

/** The client token */
export const selectClientToken = createSelector(selectSurveyState, surveyState => surveyState.clientToken);

export const selectSurveyView = createSelector(selectSurveyState, surveyState => surveyState.surveyView);

/** Survey package id */
export const selectSurveyOwnerPackageId = createSelector(
  selectSurveyState,
  surveyState => surveyState.surveyOwnerPackageId
);

/** The survey format (eg. classic or OQAAT) */
export const selectSurveyFormat = createSelector(
  selectSurvey,
  survey => survey?.format ?? SurveyFormat.ONE_QUESTION_AT_A_TIME
);

/** Object containing all answers for the current page */
export const selectAnswers = createSelector(selectSurveyState, surveyState => surveyState.answers);

export const selectQuestionsWithAnswersCount = createSelector(selectSurveyState, surveyState => {
  const completedAnswersArray = Object.values(surveyState.questionsWithAnswers ?? {});
  return completedAnswersArray.filter(a => a.values.length).length;
});

/** Object containing all answers for the current page */
export const selectQuestions = createSelector(selectSurveyState, surveyState => surveyState.questions);

/** The answer object for the question specified by Id */
export const selectAnswerById = createSelector(
  [selectAnswers, (_, questionId: string) => questionId],
  (answers, questionId) => answers?.[questionId]
);

/**
 * The total number of questions that have a value (i.e. answered), on the current page
 * note: currently only works with single-value questions
 * TODO: refactor to include answered questions on other pages once we get that data from the backend
 *  */
export const selectTotalAnswers = createSelector(selectAnswers, answers =>
  !answers ? 0 : Object.values(answers).reduce((acc: number, answer) => (answer.values?.[0]?.value ? acc + 1 : acc), 0)
);
export const selectPageIds = createSelector(selectSurveyState, surveyState => surveyState.pageIds ?? emptyPageIds);

export const selectCurrentPageId = createSelector(
  selectSurveyState,
  surveyState => surveyState.activePage?.surveyPage?.id
);

export const selectCollectorKey = createSelector(selectSurveyState, surveyState => surveyState.collectorKey);

export const selectEncrytedSurveyParams = createSelector(
  selectSurveyState,
  surveyState => surveyState.encrytedSurveyParams
);

export const selectSurveyChangedOnPageReload = createSelector(
  selectSurveyState,
  surveyState => surveyState.surveyChangedOnPageReload
);

export const selectIsWhiteLabel = createSelector(selectSurveyState, surveyState => surveyState.isWhiteLabel);

// ========== EXPORT

export default surveySlice.reducer;
